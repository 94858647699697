@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-family: 'Poppins';
}

.active {
    color: #8F19E7;
    font-weight: 600;
    font-size: 16px;
}

.article {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 5em;
}


.about-topnav {
  overflow: hidden;
  padding: 10px 35px;
  margin: 0px 10px;
}

.about-topnav a {
  float: left;
  color: black;
  text-align: center;
  padding: 6px 36px;

  font-family: poppins;
  font-weight: 500;
  text-decoration: none;
  font-size: 15px;
}

.about-topnav a:hover {
  color: #8f19e7;
  fill-color-style: purple;
  fill: Solid;
  font-weight: 600;
}

.about-topnav a.active {
}


@media screen and (max-width: 768px) {
  .about-topnav {
    padding: 5px 15px;
    margin: 0px 5px;
    display: inline-block;
  }
  .about-topnav a {
    color: black;
    text-align: center;
    padding: 5px 15px;
    line-height: 27px;
    font-family: poppins;
    font-weight: 500;
    text-decoration: none;
    font-size: 15px;
    float: none;
    display: block;
    text-align: left;
  }
}

