.topnav {
    overflow: hidden;
    padding: 10px 25px;
    margin: 0px 10px;
    display: flex;
    justify-content: space-around;
}

.topnav a {
    float: left;
    text-align: center;
    padding: 6px 6px;
    font-family: poppins;
    text-decoration: none;
}

.topnav a:hover {
    color: #8F19E7;
    Fill: Solid;
    font-weight: 600;
}

.history {
    padding: 5px 55px;
    color: #1C1C1C;
    font-family: Poppins;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 400;

}

.heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}

.video {
    padding: 30px;
    margin: 90px 0px 90px 0px;

}


@media screen and (max-width: 768px) {
    .topnav {
        padding: 5px 15px;
        margin: 0px 5px;
        display: inline-block;
    }

    .topnav a {
        text-align: center;
        padding: 5px 15px;
        line-height: 27px;
        font-family: poppins;
        text-decoration: none;
        float: none;
        display: block;
        text-align: left;
    }

    .video {
        padding: 20px;
        margin: 40px 0px 40px 0px;
    }
}